import { createContext, useContext, useState } from "react";
import { useAuth } from "../Auth/AuthContext";
import { useMessage } from "../Message/MessageContext";
import { DocumentoPessoa, ApiUrl } from "../../api/api";

const PessoaDocumentoContext = createContext({});

const emptyValue = { saved: [], unsaved: [] };

export const avatarType = "avatar";
export const tipoDocumento = [
  "CPF",
  "CPF Mãe",
  "CPF Pai",
  "Cartão do SUS",
  "Certidão de Nascimento",
  "Comprovante de Renda",
  "Comprovante de Residência",
  "Outros",
  "RG",
  "RG Mãe",
  "RG Pai",
];

export function PessoaDocumentoProvider({ children }) {
  const { user } = useAuth();
  const { setError } = useMessage();
  const [documentos, setDocumentos] = useState(emptyValue);
  const [removed, setRemoved] = useState([]);

  const saveDocumentos = async (pessoaId) => {
    const addPessoaId = (d) => ({ ...d, pessoa: pessoaId });

    await Promise.allSettled(
      removed.map((d) => DocumentoPessoa(user.jwt).remove(d.id))
    );

    let addedItems = await Promise.allSettled(
      documentos.unsaved.map((d) =>
        d.created_at
          ? DocumentoPessoa(user.jwt).update(d.id, { tipo: d.tipo })
          : DocumentoPessoa(user.jwt).add(addPessoaId(d))
      )
    );

    setRemoved([]);
    setDocumentos({
      saved: [
        ...documentos.saved,
        ...addedItems
          .filter((d) => d.status === "fulfilled")
          .map((d) => d.value.data),
      ],
      unsaved: [],
    });
  };

  const getDocumentos = async (pessoaId) => {
    try {
      const { data } = await DocumentoPessoa(user.jwt).get(pessoaId);
      setDocumentos({
        unsaved: [],
        saved: data.filter((d) => d.documento?.length > 0),
      });
    } catch (e) {
      setError("Problema ao tentar carregar documentos do paciente");
    }
  };

  const removeDocumento = (d) => {
    if (d.created_at) {
      setRemoved([...removed, d]);
      return setDocumentos({
        ...documentos,
        saved: documentos.saved.filter((i) => i.id !== d.id),
      });
    }

    setDocumentos({
      ...documentos,
      unsaved: documentos.unsaved.filter((i) => i.id !== d.id),
    });
  };

  const updateTipoDocumento = (d) => {
    if (d.created_at) {
      return setDocumentos({
        unsaved: [...documentos.unsaved.filter((i) => i.id !== d.id), d],
        saved: documentos.saved.filter((i) => i.id !== d.id),
      });
    }

    setDocumentos({
      ...documentos,
      unsaved: [...documentos.unsaved.filter((i) => i.id !== d.id), d],
    });
  };

  const showDocumento = (doc, isThumbnail) => {
    const savedDoc = isThumbnail ? doc?.formats?.thumbnail?.url : doc?.url;
    if (!doc?.data && !savedDoc) return;
    return doc?.data || ApiUrl + savedDoc;
  };

  const value = {
    documentos: {
      saved: documentos.saved.filter((d) => d.tipo !== avatarType),
      unsaved: documentos.unsaved.filter((d) => d.tipo !== avatarType),
    },
    avatar:
      documentos.unsaved.find((d) => d.tipo === avatarType) ||
      documentos.saved.find((d) => d.tipo === avatarType),
    addDocumentos: (d) =>
      setDocumentos({ ...documentos, unsaved: [...documentos.unsaved, ...d] }),
    removeDocumento,
    saveDocumentos,
    getDocumentos,
    clearDocumentos: () => setDocumentos(emptyValue),
    updateTipoDocumento,
    showDocumento,
  };

  return (
    <PessoaDocumentoContext.Provider value={value}>
      {children}
    </PessoaDocumentoContext.Provider>
  );
}

export function usePessoaDocumento() {
  return useContext(PessoaDocumentoContext);
}
